import { environment } from '@env';

import { HttpClient } from '@angular/common/http';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { User } from '@config/models';
import { userPermissions } from '@config/permissions';
import { HttpHelper } from '@saikin/http';

@Injectable({ providedIn: 'root' })
export class AuthService
{
  public modulesChanged: EventEmitter<void> = new EventEmitter<void>();
  public currentUserSubject: BehaviorSubject<User>;
  private currentTokenSubject: BehaviorSubject<string>;

  private http: HttpHelper;
  private modules: Array<string> = [];

  constructor(@Inject(HttpClient) http: HttpClient)
  {
    this.currentUserSubject = new BehaviorSubject<User>(
        JSON.parse(localStorage.getItem('currentUser')));

    this.currentTokenSubject = new BehaviorSubject<string>(
        localStorage.getItem('authorizationToken'));

    this.http = new HttpHelper(http, environment.serverHostAddress);
  }

  public canDo(permission: string): boolean
  {
    return true;
    // const _permissions = userPermissions[this.currentUser.role] || [];
    // return _permissions.indexOf(permission) > -1;
  }

  public get currentUser(): User
  {
    return this.currentUserSubject.value;
  }

  public get token(): string
  {
    return this.currentTokenSubject.value;
  }

  public async login(username: string, password: string): Promise<void>
  {
    const response = await this.http
      .post('/auth/login',
            { username, password },
            {'Content-Type': 'application/json'}, { responseType: 'text' } );
    const token = response.body;

    if (token) {
      await this.loginWithToken(token);
    }
  }

  public async loginWithToken(token: string): Promise<boolean>
  {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    };

    this.currentTokenSubject.next(token); // TODO: why?

    try {
      const response = await this.http.get('/auth', headers);
      if (response) {
        const user = User.fromResponse(response.body);
        this.storeCredentials(user, token);
        return true;
      }
    }
    catch (e)  {
      return false;
    }
  }

  private storeCredentials(user: User, token: string): void
  {
    localStorage.setItem('authorizationToken', token);

    this.currentTokenSubject.next(token);
    this.currentUserSubject.next(user);
  }

  public async checkTokenValidity(): Promise<boolean>
  {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    };

    try {
      await this.http.head('/auth', headers);
      return true;
    }
    catch (e) {
      return false;
    }
  }

  public async passwordForgotten(email: string): Promise<void>
  {
    await this.http.put('/auth/password_forgotten',
                        { username: email },
                        {'Content-Type': 'application/json'});
  }

  public logout(): void
  {
    // remove user from local storage to log user out
    localStorage.removeItem('authorizationToken');
    this.currentTokenSubject.next(undefined);
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(undefined);
  }
}

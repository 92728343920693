import { CnstService } from '@saikin/cnst/cnst.service';

import { ProductImage } from '@config/models';
import { Injectable } from "@angular/core";

@Injectable()
export class EngravingService extends CnstService
{
  public async getAllImages(): Promise<Array<ProductImage>>
  {
    const url = '/productgroups/0/products/1';
    const response = await this.http.get(url, this.getHeaders());
    return response.body.images.map(i => ProductImage.fromResponse(i));
  }
}

import { environment } from '@env';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { AuthService } from '@saikin/auth/auth.service';
import { HttpHelper, RequestResult } from '@saikin/http';

@Injectable()
export abstract class CnstService
{
  protected http: HttpHelper;
  protected baseUrl: string;

  constructor(
      @Inject(HttpClient) http: HttpClient,
      protected authService: AuthService
  )
  {
    this.baseUrl = environment.serverHostAddress;
    this.http = new HttpHelper(http, this.baseUrl);
  }

  protected getHeaders(): { [header: string]: string }
  {
    return {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.authService.token
    };
  }

  protected applyEtag(object: any, response: RequestResult): void
  {
    object.etag = response.headers.get('etag').replace(/"/g, '');
  }
}

<h1 mat-dialog-title>Produktgruppe löschen</h1>

<div mat-dialog-content>
  <p>Wollen Sie wirklich folgende Produktgruppe (inkl. Produkte) löschen?</p>
  <strong>{{ productGroup.name }}</strong>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="cancel()" cdkFocusInitial
         [attr.data-action]="'cancel-delete-product-group'">Abbrechen</button>
  <button mat-button [mat-dialog-close]="productGroup.id"
         [attr.data-action]="'confirm-delete-product-group'">Ja, löschen</button>
</div>

import { CnstBaseModel, CnstModel } from '@saikin/cnst/cnst-model';

@CnstModel('ProductImage')
export class ProductImage extends CnstBaseModel
{
  public path: string = '';
  public caption: string = '';
  public thumbnail?: string;

  public static fromResponse(response: any): ProductImage
  {
    if (!response) {
      return undefined;
    }

    const model = super.fromResponse(response);
    if (model.path) {
      model.thumbnail = model.path.replace('.jpg', '.th.jpg') +
                        '?d=' + Date.now();
      model.path = model.path + '?d=' + Date.now();
    }
    return model;
  }

  public toRequest(): any
  {
    const data = super.toRequest();
    if (data.path) {
      data.path = data.path.split('?d=')[0];
      delete data.thumbnail;
    }
    return data;
  }
}

@CnstModel('Product')
export class Product extends CnstBaseModel
{
  public name: string = '';
  public price: number = 0;
  public isActive: boolean = true;

  public groupId?: string = undefined;

  @CnstModel('ProductImage')
  public images: Array<ProductImage> = [];
}

@CnstModel('ProductGroup')
export class ProductGroup extends CnstBaseModel
{
  public name: string = '';
  public type: string = 'general';
  public isActive: boolean = true;
  public catchphrase: string = '';
  public description: string = '';

  @CnstModel('ProductImage')
  public image: ProductImage = undefined;

  @CnstModel('Product')
  public products: Array<Product> = [];

  public static fromResponse(response: any): ProductGroup
  {
    const model = super.fromResponse(response);
    model.isActive = !!model.isActive;
    return model;
  }

  public toRequest(): any
  {
    const data = super.toRequest();
    delete data.products;

    return data;
  }
}

import { Component, ElementRef, EventEmitter, Input,  OnChanges, OnInit,
         Output, SimpleChanges, ViewChild
       } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'cnst-auto-combobox',
  templateUrl: './auto-combobox.component.html',
})
export class CnstAutoComboboxComponent implements OnChanges, OnInit
{
  @Input() data: Array<any>;
  @Input() displayField: string | Array<string>;
  @Input() placeholder: string;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;

  @Input() value: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() selected: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('searchField') public searchField: ElementRef;

  public dropdownControl: UntypedFormControl = new UntypedFormControl();
  public filteredOptions: Observable<string[]>;

  private entries: Array<any> = [];

  public ngOnInit(): void
  {
    this.filteredOptions =
      this.dropdownControl.valueChanges.pipe(
        startWith(''),
        filter(x => typeof x === 'string'),
        map(value => typeof value === 'string' ? value : this.toString(value)),
        map(name => name
            ? this.entries.filter(o => o.display.toLowerCase()
                .indexOf(name.toLowerCase()) > -1)
            : this.entries.slice()
        )
      );
  }

  public ngOnChanges(changes: SimpleChanges): void
  {
    if (this.data) {
      this.entries = this.data.map(value => {
        return { value, display: this.toString(value) };
      });
    }
    if (changes['value']) {
      this.value = changes['value'].currentValue;
      this.dropdownControl.setValue(
          this.toString(this.value), { emitEvent: true });
    }
    this.displayList();
  }

  public emitSelection(selection: any, event): void
  {
    if (!event || (event && event.isUserInput)) {
      this.valueChange.emit(selection);
      this.value = selection;
      this.valueChange.emit(selection);

      this.selected.emit(selection);
      this.searchField.nativeElement.value = this.toString(selection);
    }
  }

  public displayList(): void
  {
    const value = !this.dropdownControl.value ?
        '' : this.dropdownControl.value;
    this.dropdownControl.setValue(value, { emitEvent: true });
  }

  public toString(object: any): string
  {
    if (object && typeof object === 'object') {
      return typeof this.displayField === 'string'
          ? (
            typeof object[this.displayField] === 'function'
              ? object[this.displayField]()
              : object[this.displayField]
          )
          : (this.displayField instanceof Array
              ? this.displayField.map(d => object[d]).join(' ')
              : '');
    }
    else if (typeof object === 'string') {
      return object;
    }
    return '';
  }
}

<mat-nav-list>
  <mat-accordion>
    <ng-container *ngFor="let item of navigationItems">

      <a mat-list-item
         *ngIf="!item.children || item.children.length == 0"

         [routerLink]="item.link"
         [routerLinkActive]="['active']"
         [routerLinkActiveOptions]="{exact: true}"
      >
        <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
        {{ item.label }}
      </a>


      <mat-expansion-panel [@.disabled]="true"
          *ngIf="item.children && item.children.length > 0"
          #rla="routerLinkActive" routerLinkActive="active-link"
          [expanded]="rla.isActive">

        <ng-container *ngIf="item.additionalLinks">
          <span *ngFor="let link of item.additionalLinks"
                style="display: none" [routerLink]="link"></span>
        </ng-container>

        <mat-expansion-panel-header>
          <a mat-list-item
             [routerLink]="item.link">
            <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
            {{ item.label }}
          </a>
        </mat-expansion-panel-header>

        <mat-divider></mat-divider>

        <mat-nav-list *ngIf="item.children && item.children.length > 0">
          <ng-container *ngFor="let subitem of item.children">
            <a *ngIf="!subitem.disabled"
               mat-list-item
               [routerLink]="subitem.link"
               [routerLinkActive]="['active']"
            >
              <mat-icon *ngIf="subitem.icon">{{ subitem.icon }}</mat-icon>
              <span>{{ subitem.label }}</span>
            </a>

            <a *ngIf="subitem.disabled" mat-list-item [disableRipple]="true"
               class="disabled">
              <span>{{ subitem.label }}</span>
            </a>

          </ng-container>
        </mat-nav-list>

        <mat-divider></mat-divider>
      </mat-expansion-panel>
    </ng-container>

    <a mat-list-item (click)="logout.emit()">
      <mat-icon>directions_run</mat-icon> logout
    </a>
  </mat-accordion>
</mat-nav-list>

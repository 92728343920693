import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LoadingIndicator } from '@saikin/util';

@Component({
  selector: 'cnst-button-section',
  templateUrl: './button-section.component.html',
})
export class CnstButtonSectionComponent
{
  @Input() objectId: number;
  @Input() prefix: string;
  @Input() waitForIt: LoadingIndicator = new LoadingIndicator();
  @Input() buttons: Array<string> = [ 'add', 'clear', 'delete', 'edit' ];

  @Output() add: EventEmitter<void> = new EventEmitter<void>();
  @Output() clear: EventEmitter<void> = new EventEmitter<void>();
  @Output() delete: EventEmitter<void> = new EventEmitter<void>();
  @Output() edit: EventEmitter<void> = new EventEmitter<void>();

  @Output() addAndRepeat: EventEmitter<void> = new EventEmitter<void>();
  @Output() addAndBack: EventEmitter<void> = new EventEmitter<void>();
}

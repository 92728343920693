import { NgModule } from '@angular/core';

import { FormatNumberRange } from './format-number-range.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SafeUrlPipe } from './safe-url.pipe';

@NgModule({
  declarations: [ FormatNumberRange, SafeHtmlPipe, SafeUrlPipe ],
  exports: [ FormatNumberRange, SafeHtmlPipe, SafeUrlPipe ],
})
export class PipesModule { }

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@saikin/auth/auth.guard';

import { ProductEntryView } from './product.entry';

import { EditProductGroupComponent
       } from './edit-product-group/edit-product-group.component';
import { ProductGroupsOverviewComponent
       } from './product-groups-overview/product-groups-overview.component'; // tslint:disable-line

const routing: Routes = [
  {
    path: 'products',
    canActivate: [AuthGuard],
    component: ProductEntryView,
    children: [
      {
        path: '',
        redirectTo: '/products/overview',
        pathMatch: 'full'
      },
      {
        path: 'overview',
        canActivate: [AuthGuard],
        component: ProductGroupsOverviewComponent
      },
      {
        path: 'add',
        canActivate: [AuthGuard],
        component: EditProductGroupComponent
      },
      {
        path: 'edit/:id',
        canActivate: [AuthGuard],
        component: EditProductGroupComponent,
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routing)],
  exports: [RouterModule]
})
export class ProductRouting { }

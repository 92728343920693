<article class="component">
  <h1>Übersicht</h1>

  <h3 class="secondary">Produktgruppen</h3>
  <section>
    <ul>
      <li *ngFor="let group of groups">
        <strong>{{ group.name }}:</strong>
        ({{ group.active }} / {{ group.active + group.inactive }} aktiv)
      </li>
    </ul>
  </section>

  <h3 class="secondary">offene Rechnungen</h3>
  <section>
    <ul>
      <li *ngFor="let bill of openBills">
        <strong>{{ bill.billNumber }}</strong>:
        <a [routerLink]="['/billing/edit', bill.id]">
          {{ bill.address.getName() }}
        </a>
        vom {{ bill.invoiceDate | date: 'dd.MM.yyyy' }}
      </li>
    </ul>
  </section>
</article>

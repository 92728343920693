import { CnstService } from '@saikin/cnst/cnst.service';

import { Product } from '@config/models';
import { Injectable } from "@angular/core";

@Injectable()
export class ProductService extends CnstService
{
  public async getAllByGroupId(groupId: string): Promise<Array<Product>>
  {
    const url = '/productgroups/' + groupId + '/products';
    const response = await this.http.get(url, this.getHeaders());
    return response.body.map(p => Product.fromResponse(p));
  }

  public async create(product: Product): Promise<void>
  {
    const payload = product.toRequest();
    const url = '/productgroups/' + product.groupId + '/products';
    const response = await this.http.post(url, payload, this.getHeaders());

    product.id = response.body.id;
    product.etag = response.headers.get('etag').replace(/"/g, '');
  }

  public async update(product: Product): Promise<void>
  {
    const payload = product.toRequest();
    const url = '/productgroups/' + product.groupId + '/products/' + product.id;
    const headers = {
        ...this.getHeaders(),
        'If-Match': product.etag
    };

    const response = await this.http.patch(url, payload, headers);
    product.etag = response.headers.get('etag').replace(/"/g, '');
  }

  public async delete(groupId: string, product: Product): Promise<void>
  {
    const url = '/productgroups/' + groupId + '/products/' + product.id;
    const headers = {
        ...this.getHeaders(),
        'If-Match': product.etag
    };
    await this.http.delete(url, headers);
  }
}

import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { AuthService } from './auth.service';

@Directive({
  selector: '[canDo]'
})
export class AuthCanDoDirective
{
  constructor(
      private templateRef: TemplateRef<any>,
      private viewContainer: ViewContainerRef,
      private authService: AuthService
  ) {}

  @Input() set canDo(permission: string)
  {
    if (this.authService.canDo(permission)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
    else {
      this.viewContainer.clear();
    }
  }
}

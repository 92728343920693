import { Component } from '@angular/core';

@Component({
  selector: 'billing-routing',
  template: `
    <article class="component">
      <h1>Rechnungen</h1>
      <router-outlet></router-outlet>
    </article>
  `,
  styles: [ 'section table { width: 100%; }' ]
})
export class BillingEntryView {}

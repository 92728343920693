import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { AuthModule } from '@saikin/auth/auth.module';
import { CnstModule } from '@saikin/cnst/cnst.module';
import { MatModule } from '@saikin/mat.module';

import { BillingEntryView } from './billing.entry';
import { BillingRouting } from './billing.routing';
import { BillingService } from './billing.service';

import { EditInvoiceComponent } from './edit-invoice/edit-invoice.component';
import { InvoicesOverviewComponent
       } from './invoices-overview/invoices-overview.component';

@NgModule({
    declarations: [
        BillingEntryView,
        InvoicesOverviewComponent,
        EditInvoiceComponent,
    ],
    exports: [
        BillingEntryView,
    ],
    imports: [
        AuthModule,
        BrowserModule,
        CnstModule,
        FormsModule,
        MatModule,
        ReactiveFormsModule,
        RouterModule,
        BillingRouting,
    ],
    providers: [
        BillingService,
        DatePipe
    ]
})
export class BillingModule { }

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot
       } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate
{
  constructor(
      private router: Router,
      protected authService: AuthService
  ) {}

  public async canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Promise<boolean>
  {
    if (await this.canAuth()) {
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }

  protected async canAuth(): Promise<boolean>
  {
    const isLoggedIn = this.authService.currentUser &&
                       await this.authService.checkTokenValidity();
    if (isLoggedIn) {
      return true;
    }

    const canLogIn = this.authService.token &&
                     await this.authService.loginWithToken(
                        this.authService.token);
    if (canLogIn) {
      return true;
    }
  }
}

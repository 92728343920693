import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from "@angular/core";

export const CNST_DATE_FORMATS = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
  },
  display: {
    // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'long' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};

@Injectable()
export class CnstDateAdapter extends NativeDateAdapter
{
  public format(date: Date, displayFormat: any): string
  {
    const leadingZero = (n: number) => ('00' + n).slice(-2);

    if (displayFormat === 'input') {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return leadingZero(day) + '.' + leadingZero(month) + '.' + year;
    }
    else {
      return date.toDateString();
    }
  }
}

import { CnstService } from '@saikin/cnst/cnst.service';

import { ProductGroup } from '@config/models';
import { Injectable } from "@angular/core";

@Injectable()
export class ProductGroupService extends CnstService
{
  public async getAll(): Promise<Array<ProductGroup>>
  {
    const response = await this.http.get('/productgroups', this.getHeaders());
    return response.body.map(p => ProductGroup.fromResponse(p));
  }

  public async getById(id: string): Promise<ProductGroup>
  {
    const response =
        await this.http.get('/productgroups/' + id, this.getHeaders());

    return ProductGroup.fromResponse(response.body);
  }

  public async create(productGroup: ProductGroup): Promise<ProductGroup>
  {
    const payload = productGroup.toRequest();
    const response =
        await this.http.post('/productgroups', payload, this.getHeaders());
    productGroup = ProductGroup.fromResponse(response.body);
    return productGroup;
  }

  public async update(productGroup: ProductGroup): Promise<ProductGroup>
  {
    const payload = productGroup.toRequest();
    const headers = {
        ...this.getHeaders(),
        'If-Match': productGroup.etag
    };

    const response = await this.http
      .patch('/productgroups/' + productGroup.id, payload, headers);
    productGroup = ProductGroup.fromResponse(response.body);
    return productGroup;
  }

  public async delete(productGroup: ProductGroup): Promise<void>
  {
    const headers = {
        ...this.getHeaders(),
        'If-Match': productGroup.etag
    };
    await this.http.delete('/productgroups/' + productGroup.id, headers);
  }
}

import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { ProductGroup } from '@config/models';
import { LoadingIndicator } from '@saikin/util';

import { ProductGroupService } from '../productgroup.service';

@Component({
  selector: 'product-groups-overview',
  templateUrl: './product-groups-overview.component.html',
  styleUrls: ['./product-groups-overview.component.scss']
})
export class ProductGroupsOverviewComponent implements OnInit
{
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public filterValue: string = '';

  private productGroups: Array<ProductGroup> = [];
  public dataSource: MatTableDataSource<ProductGroup>;

  public loading: LoadingIndicator = new LoadingIndicator();

  constructor(
    private productGroupService: ProductGroupService,
    private cdr: ChangeDetectorRef
  ) { }

  public async ngOnInit(): Promise<void>
  {
    this.loading.state = true;
    this.productGroups = await this.productGroupService.getAll();
    this.createDataSource(this.productGroups);
    this.loading.state = false;
  }

  public sortData(sort: Sort): void
  {
    let data = this.productGroups.slice();
    if (sort.active && sort.direction !== '') {
      const isAsc = sort.direction === 'asc';
      const compare = (a, b) => {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
      };

      data = data.sort((a, b) => {
        switch (sort.active) {
          case 'name': return compare(a.name, b.name);
          case 'type': return compare(a.type, b.type);
          // case 'count': return compare(a.products.length, b.products.length);
          case 'modified': return compare(a.modified, b.modified);
          case 'active': return compare(a.isActive, b.isActive);
          default: return 0;
        }
      });
    }

    this.createDataSource(data);
    this.applyFilter();
  }

  public applyFilter(): void
  {
    this.filterValue = this.filterValue.trim();
    this.filterValue = this.filterValue.toLowerCase();
    this.dataSource.filter = this.filterValue;
  }

  private createDataSource(data: Array<ProductGroup>): void
  {
    this.dataSource = new MatTableDataSource(data);
    this.cdr.detectChanges();
    this.dataSource.paginator = this.paginator;
  }

  public async setIsActive(group: ProductGroup, active: boolean): Promise<void>
  {
    group.isActive = active;
    await this.productGroupService.update(group);
  }
}

import { Injectable } from '@angular/core';
import { deepCopy } from '@saikin/util';

// TOOD: hinzugefügt, weil im ProductionMode die Models nicht über
//       "window" geladen werden können (uglify / minify zerschießt da was)

@Injectable()
export class CnstModelStorage
{
  static instance: CnstModelStorage;
  private models: any = {};

  constructor()
  {
    CnstModelStorage.instance = this;
  }

  public addModel(model: any): void
  {
    this.models[model.cnstName] = model;
  }

  public getModelDefinition(modelName: string): any
  {
    return this.models[modelName];
  }

  public loadModel(modelName: string): any
  {
    return new (<any> this.models)[modelName]();
  }
}

export function CnstModel(model: string): any
{
  return (instance: any, key: string, descriptor: PropertyDescriptor) => {
    instance['cnstName'] = model;
    if (key === undefined) {
      instance.prototype.cnstName = model;
    }
    else {
      instance['_' + key] = model;
    }
  };
}

export class CnstBaseModel
{
  public id?: string;
  public etag?: string;
  public created?: string;
  public modified?: string;
  public __?: any = {};

  public static fromResponse(response): any
  {
    const modelStorage = CnstModelStorage.instance;
    const object = modelStorage.loadModel((<any> this).cnstName);

    const defaults = {
      id: 'id',
      etag: 'etag',
      creation_date: 'created',
      modification_date: 'modified',
    };

    if (!response) {
      return undefined;
    }

    for (const key of Object.keys(defaults)) {
      if (response[key]) {
        object[defaults[key]] = response[key];
        delete response[key];
      }
    }

    for (const camelKey of Object.getOwnPropertyNames(object)) {
      const snakeKey =
          camelKey.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

      if (response[snakeKey] !== undefined) {
        const propClassName = object['_' + camelKey];

        if (!propClassName) {
          object[camelKey] = response[snakeKey];
        }
        else {
          const propClass = modelStorage.getModelDefinition(propClassName);
          if (Array.isArray(object[camelKey])) {
            for (const subProperty of response[snakeKey]) {
              object[camelKey].push(propClass.fromResponse(subProperty));
            }
          }
          else if (propClass) {
            object[camelKey] = propClass.fromResponse(response[snakeKey]);
          }
          else {
            object[camelKey] = response[snakeKey];
          }
        }

        delete response[snakeKey];
      }
    }

    return object;
  }

  public static fromTemplate(template: any): any
  {
    const modelStorage = CnstModelStorage.instance;
    const object = modelStorage.loadModel((<any> this).cnstName);

    const base = template.clone();
    for (const key of Object.getOwnPropertyNames(template)) {
      object[key] = base[key];
    }

    return object;
  }

  public clone(): any
  {
    const modelStorage = CnstModelStorage.instance;
    const newInstance = modelStorage.loadModel((<any> this).cnstName);

    for (const key of Object.getOwnPropertyNames(this)) {
      const propClassName = this['_' + key];

      if (!propClassName) {
        newInstance[key] = this[key];
      }
      else {
        if (Array.isArray(this[key])) {
          for (const subProperty of this[key]) {
            newInstance[key].push(subProperty.clone());
          }
        }
        else {
          newInstance[key] = this[key].clone();
        }
      }
    }

    return newInstance;
  }

  public toRequest(): any
  {
    const deleteFunctions = (requestObject) => {
      for (const key of Object.getOwnPropertyNames(requestObject)) {
        if (typeof requestObject[key] === 'function') {
          delete requestObject[key];
        }
      }

      return requestObject;
    };
    const deleteLeadingUnderscore = (requestObject) => {
      for (const key of Object.getOwnPropertyNames(requestObject)) {
        if (key.startsWith('_')) {
          delete requestObject[key];
        }
      }

      return requestObject;
    };

    const request = <any> deepCopy(this);

    const defaults = [ 'modified', 'created', 'etag' ];
    for (const key of defaults) {
      if (request[key]) {
        delete request[key];
      }
    }

    for (const camelKey of Object.getOwnPropertyNames(this)) {
      const snakeKey =
          camelKey.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

      if (this[camelKey] instanceof CnstBaseModel) {
        request[snakeKey] = this[camelKey].toRequest();
      }
      else if (Array.isArray(this[camelKey])) {
        request[snakeKey] = [];
        for (const item of this[camelKey]) {
          if (item instanceof CnstBaseModel) {
            request[snakeKey].push(item.toRequest());
          }
          else {
            request[snakeKey].push(item);
          }
        }
      }
      else {
        request[snakeKey] = this[camelKey];
      }

      if (snakeKey !== camelKey) {
        delete request[camelKey];
      }
    }

    return deleteLeadingUnderscore(deleteFunctions(request));
  }

  public toString(): string
  {
    return '[' + (<any> this).cnstName + '] ' + this.id;
  }
}

<h3 class="primary">Übersicht Produktgruppen</h3>
<section>
  <mat-form-field>
    <input matInput (keyup)="filterValue = $event.target.value; applyFilter()"
           placeholder="Filter"
           name="product_groups_overview_filter">
  </mat-form-field>

  <br />

  <a [routerLink]="['/products/add']"
     [attr.data-action]="'create-new-productgroup'">
    <button mat-raised-button>neue Produktgruppe anlegen</button>
  </a>

</section>

<section>
  <img *ngIf="loading.state" [src]="loading.imagePath" />

  <div class="mat-elevation-z8" *ngIf="dataSource !== undefined">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Name
        </mat-header-cell>
        <mat-cell *matCellDef="let group"> {{ group.name }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Type
        </mat-header-cell>
        <mat-cell *matCellDef="let group"> {{ group.type }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="active">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Aktiv
        </mat-header-cell>
        <mat-cell *matCellDef="let group">
          <mat-checkbox
              [checked]="group.isActive"
              (change)="setIsActive(group, $event.checked)"
              name="change_product_group_isActive"
          >aktiv</mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="modified">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          zuletzt bearbeitet
        </mat-header-cell>
        <mat-cell *matCellDef="let group">
          {{ group.modified | date: 'dd.MM.yyyy HH:mm:ss' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="options">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let group">
          <button mat-button [routerLink]="['/products/edit', group.id]">
            <mat-icon>edit</mat-icon> Bearbeiten
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="['name', 'type', 'active', 'modified', 'options']"
      ></mat-header-row>
      <mat-row *matRowDef="let product; columns: ['name', 'type', 'active',
                                                  'modified', 'options'];">
      </mat-row>
    </mat-table>
    <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"
    ></mat-paginator>
  </div>
</section>

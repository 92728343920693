import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { Invoice } from '@config/models';
import { accessBlobUrl, LoadingIndicator } from '@saikin/util';

import { BillingService } from '../billing.service';

@Component({
  selector: 'invoices-overview',
  templateUrl: './invoices-overview.component.html',
  styleUrls: ['./invoices-overview.component.scss']
})
export class InvoicesOverviewComponent implements OnInit
{
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public filterValue: string = '';

  private invoices: Array<Invoice> = [];
  public dataSource: MatTableDataSource<Invoice>;

  public loading: LoadingIndicator = new LoadingIndicator();

  constructor(
    private billingService: BillingService,
    private cdr: ChangeDetectorRef
  ) { }

  public async ngOnInit(): Promise<void>
  {
    this.loading.state = true;
    this.invoices = await this.billingService.getAll();
    this.invoices = this.invoices.map(b => {
      b._total = b.items.reduce((sum, i) => sum + (i.price * i.amount), 0);
      return b;
    });

    this.createDataSource(this.invoices);
    this.loading.state = false;
  }

  public sortData(sort: Sort): void
  {
    let data = this.invoices.slice();
    if (sort.active && sort.direction !== '') {
      const isAsc = sort.direction === 'asc';
      const compare = (a, b) => {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
      };

      data = data.sort((a, b) => {
        switch (sort.active) {
          case 'id': return compare(a.billNumber, b.billNumber);
          case 'name': return compare(
              a.address.lastName + a.address.firstName,
              b.address.lastName + b.address.firstName);
          case 'created': return compare(a.invoiceDate, b.invoiceDate);
          case 'payed': return compare(a.payedDate, b.payedDate);
          case 'total': return compare(a._total, b._total);
          default: return 0;
        }
      });
    }

    this.createDataSource(data);
    this.applyFilter();
  }

  public applyFilter(): void
  {
    const filterValue = this.filterValue.trim().toLowerCase();
    this.dataSource.filterPredicate = (data: any, filter: string) =>
        !filter ||
        data.billNumber.toLowerCase().search(filter) > -1 ||
        data.address.firstName.toLowerCase().search(filter) > -1 ||
        data.address.lastName.toLowerCase().search(filter) > -1 ||
        data.address.company.toLowerCase().search(filter) > -1 ||
        ('' + data._total).search(filter) > -1 ||
        data.invoiceDate.search(filter) > -1 ||
        ('' + data.payedDate).search(filter) > -1;
    this.dataSource.filter = filterValue;
  }

  private createDataSource(data: Array<Invoice>): void
  {
    this.dataSource = new MatTableDataSource(data);
    this.cdr.detectChanges();
    this.dataSource.paginator = this.paginator;
  }

  public async downloadPDF(invoice: Invoice, original: boolean = true):
    Promise<void>
  {
    try {
      const documentId = 'Rechnung-' + invoice.billNumber;
      accessBlobUrl(
          await this.billingService.getPdf(+invoice.id, original),
                                           documentId + '.pdf'
      );
    }
    catch (error) {
      console.error(error);
    }
  }
}

import { CnstBaseModel, CnstModel } from '@saikin/cnst/cnst-model';

@CnstModel('User')
export class User extends CnstBaseModel
{
  username: string = '';
  firstName: string = '';
  lastName: string = '';
  password?: string = '';
  role?: string = 'user'; // TODO: Konstante / Enum
}

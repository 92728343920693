import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@saikin/auth/auth.guard';

import { CouponEntryView } from './coupon.entry';

import { CouponsOverviewComponent
       } from './coupons-overview/coupons-overview.component';

const routing: Routes = [
  {
    path: 'coupons',
    canActivate: [AuthGuard],
    component: CouponEntryView,
    children: [
      {
        path: 'overview',
        canActivate: [AuthGuard],
        component: CouponsOverviewComponent,
      },
      {
        path: '**',
        redirectTo: '/coupons/overview',
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routing)],
  exports: [RouterModule]
})
export class CouponRouting { }

import { CnstService } from '@saikin/cnst/cnst.service';

import { Coupon } from '@config/models';
import { Injectable } from "@angular/core";

@Injectable()
export class CouponService extends CnstService
{
  public async getAll(): Promise<Array<Coupon>>
  {
    const url = '/coupons';
    const response = await this.http.get(url, this.getHeaders());
    return response.body.map(i => Coupon.fromResponse(i));
  }

  public async getPdf(): Promise<string>
  {
    const headers = {
      Authorization: 'Bearer ' + this.authService.token,
      Accept: 'application/pdf',
    };

    const response =
      await this.http.get('/coupons', headers, { responseType: 'arraybuffer' });
    return response.body;
  }

  public async update(coupon: Coupon): Promise<Coupon>
  {
    const url = '/coupons/' + coupon.id;
    const payload = coupon.toRequest();
    const headers =  {
      ... this.getHeaders(),
      'If-Match': coupon.etag
    };
    const response = await this.http.patch(url, payload, headers);
    coupon = Coupon.fromResponse(response.body);
    return coupon;
  }
}

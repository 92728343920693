import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';

import { Address, Invoice, InvoiceItem } from '@config/models';
import { accessBlobUrl, executeWithNotification, LoadingIndicator } from '@saikin/util';

import { BillingService } from '../billing.service';

@Component({
  selector: 'edit-invoice',
  templateUrl: './edit-invoice.component.html',
  styleUrls: [ './edit-invoice.component.scss' ],
})
export class EditInvoiceComponent implements OnInit
{
  public invoice: Invoice = undefined;
  public loading: LoadingIndicator = new LoadingIndicator();

  public withRefPerson: boolean = true;
  public dataSource: MatTableDataSource<InvoiceItem>;
  public invoiceDate: Date;
  public payedDate: Date;
  public settings: any;
  public addresses: Array<Address> = [];

  public isPrivatePerson: boolean = true;
  public isVoucherInvoice: boolean = false;
  public paymentMethods: Array<any> = [
    { value: 'paypal', text: 'PayPal' },
    { value: 'prepayment', text: 'Vorkasse / Überweisung' },
    { value: 'on_account', text: 'auf Rechnung' },
  ];
  public selectedPaymentMethod: string = '-';
  public readOnly: boolean = false;
  public totalSum: number = 0;

  constructor(
      private route: ActivatedRoute,
      private billingService: BillingService,
      private cdr: ChangeDetectorRef,
      private snackBar: MatSnackBar,
      private datePipe: DatePipe) { }

  public async ngOnInit(): Promise<void>
  {
    this.invoice = new Invoice();
    this.invoice.address = new Address();
    this.invoice.invoiceDate = (new Date()).toJSON();

    this.settings = await this.billingService.getNextInvoiceNumber();
    const billId = this.route.snapshot.paramMap.get('id');
    if (billId) {
      this.readOnly = true;
      this.invoice = await this.billingService.getById(+billId);
      this.payedDate = new Date(this.invoice.payedDate);
      this.createDataSource(this.invoice.items);
      for (const item of this.invoice.items) {
        if (item.label.startsWith('Gutschein')) {
          this.isVoucherInvoice = true;
          break;
        }
      }
      this.selectedPaymentMethod = this.paymentMethods
        .find(pm => pm.value === this.invoice.paymentType)?.text;
      if (!this.selectedPaymentMethod) {
        this.invoice.paymentType = 'on_account';
        this.selectedPaymentMethod = 'auf Rechnung'
      }
    }
    else {
      this.invoice.billNumber = this.settings.billNumber;
      this.addresses = await this.billingService.getAddresses();
    }

    this.invoiceDate = new Date(this.invoice.invoiceDate);
    this.updateTotalSum();
  }

  private createDataSource(data: Array<InvoiceItem>): void
  {
    this.dataSource = new MatTableDataSource(data);
    this.cdr.detectChanges();
  }

  public addressSelected(address: Address)
  {
    if (address) {
      this.invoice.address = address.clone();
    }
  }

  public async create(): Promise<void>
  {
    const callback = async () => {
      this.invoice = await this.billingService.create(this.invoice);
      this.readOnly = true;
    };

    executeWithNotification(this.snackBar, {
      callback,
      success: `Rechung "${this.invoice.billNumber}" erfolgreich angelegt.`,
      error: 'Anlegen fehlgeschlagen!',
    });
  }

  public async update(): Promise<void>
  {
    const callback = async () => {
      this.invoice = await this.billingService.update(this.invoice);
      this.readOnly = true;
    };

    executeWithNotification(this.snackBar, {
      callback,
      success: `Rechnung "${this.invoice.billNumber}" erfolgreich bearbeitet.`,
      error: 'Bearbeiten fehlgeschlagen!',
    });
  }

  public sendVoucherEmail(): void
  {
    const callback = async () => {
      const result = await this.billingService.sendVoucherEmail(this.invoice);
      if (!result) {
        throw new Error();
      }
    };

    executeWithNotification(this.snackBar, {
      callback,
      success: `E-Mail wurde an den Kunden geschickt.`,
      error: 'E-Mail-Versand fehlgeschlagen!',
    });
  }

  public addInvoiceItem(): void
  {
    const item = new InvoiceItem();
    item.taxRate = this.settings.taxRate.default;
    this.invoice.items.push(item);
    this.createDataSource(this.invoice.items);
    this.updateTotalSum();
  }

  public removeInvoiceItem(idx: number): void
  {
    this.invoice.items.splice(idx, 1);
    this.createDataSource(this.invoice.items);
    this.updateTotalSum();
  }

  public cancelInvoice(): void
  {
    const callback = async () => {
      await this.billingService.delete(this.invoice);
    };

    executeWithNotification(this.snackBar, {
      callback,
      success: `Rechnung "${this.invoice.billNumber}" erfolgreich storniert.`,
      error: 'Stornieren fehlgeschlagen!',
    });
  }

  public formatDate(date: any): string
  {
    return date
      ? this.datePipe.transform(date, 'yyyy-MM-dd') + ' 00:00:00'
      : undefined;
  }

  public updateTotalSum(): void
  {
    this.totalSum = this.invoice.items.reduce((sum, i) =>
        sum + (Number(i.price) * Number(i.amount)), 0);
  }

  public async getItemData(item: any): Promise<void>
  {
    try {
      const data = await this.billingService.getData(item.id);
      accessBlobUrl(
          data.content,
          'Kundenbild-' + item.label + '.' + data.type
      );
    }
    catch (error) {
      console.error(error);
    }
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
       } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler):
      Observable<HttpEvent<any>> {
    const req = request.clone({
      headers: request.headers
        .set('Authorization', 'Bearer ' + this.authService.token)
    });
    return next.handle(req);
  }
}

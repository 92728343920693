<article class="component">
  <h1>Login</h1>
  <section class="mx-auto">
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <mat-form-field>
          <mat-label>Username</mat-label>
          <input matInput formControlName="username"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
              placeholder="Username" value=""
              autocomplete="username"/>
          <mat-hint *ngIf="submitted && f.username.errors"
                     class="invalid-feedback">
            <div *ngIf="f.username.errors.required">Usernamen eingeben</div>
          </mat-hint>
        </mat-form-field>

        <br />

        <mat-form-field>
          <mat-label>Password</mat-label>
          <input type="password" matInput formControlName="password"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
              placeholder="Username" value=""
              autocomplete="password"/>
          <mat-hint *ngIf="submitted && f.password.errors"
                     class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Passwort eingeben</div>
          </mat-hint>
        </mat-form-field>

      </div>

      <div class="form-group">
        <button [disabled]="loading.state" class="btn btn-primary"
                [attr.data-action]="'login'">Login</button>
        <img *ngIf="loading.state" [src]="loading.imagePath" />
      </div>
    </form>

  </section>
</article>

<div *ngIf="objectId" class="control-group">
  <button *ngIf="buttons.indexOf('edit') > -1"
          mat-flat-button color="primary"
          (click)="edit.emit()"
          [disabled]="waitForIt.state"
          [attr.data-action]="'button-section-edit'">
    {{ prefix }} bearbeiten
  </button>

  <button *ngIf="buttons.indexOf('delete') > -1"
          mat-flat-button color="error"
          (click)="delete.emit()"
          [attr.data-action]="'button-section-delete'">
    {{ prefix }} löschen
  </button>
</div>

<div *ngIf="!objectId" class="control-group">
  <button *ngIf="buttons.indexOf('addAndBack') > -1"
          mat-flat-button color="primary"
          (click)="addAndBack.emit()"
          [disabled]="waitForIt.state"
          [attr.data-action]="'button-section-createAndBack'">
    Speichern und zurück
  </button>

  <button *ngIf="buttons.indexOf('addAndRepeat') > -1"
          mat-flat-button color="secondary"
          (click)="addAndRepeat.emit()"
          [disabled]="waitForIt.state"
          [attr.data-action]="'button-section-createAndRepeat'">
    Speichern
  </button>

  <button *ngIf="buttons.indexOf('add') > -1"
          mat-flat-button color="primary"
          (click)="add.emit()"
          [disabled]="waitForIt.state"
          [attr.data-action]="'button-section-create'">
    {{ prefix }} anlegen
  </button>

  <button *ngIf="buttons.indexOf('clear') > -1"
          mat-flat-button color="secondary"
          (click)="clear.emit()"
          [attr.data-action]="'button-section-empty'">
    {{ prefix }} leeren
  </button>
</div>

<img *ngIf="waitForIt && waitForIt.state" [src]="waitForIt.imagePath" />

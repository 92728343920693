import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@saikin/auth/auth.guard';
import { LoginComponent } from 'app/login/login.component';
import { HomeComponent } from './home/home.component';

export const routingConfiguration: ExtraOptions = {
    paramsInheritanceStrategy: 'always',
    relativeLinkResolution: 'legacy'
};

const routes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule]
})
export class AppRouting { }

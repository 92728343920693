<mat-sidenav-container [ngClass]="currentUser ? currentUser.role : 'default'">
  <mat-sidenav #sidenav
              [opened]="currentUser && screenWidth > 840"
              [mode]="(screenWidth > 840) ? 'side' : 'over'"
              [disableClose]="true">
    <div class="header-row nav">
      <span *ngIf="screenWidth <= 840" (click)="sidenav.toggle()"
            class="close-navigation">
        <<
      </span>
    </div>

    <ng-container *ngIf="currentUser">
      <h1>
        <img class="logo"
             src="https://www.schnapperer.de/assets/images/logo.png" />
      </h1>

      <mat-divider></mat-divider>

      <navigation [user]="currentUser" (logout)="logout()"></navigation>

      <div class="admin-footer">
        <pre>
     |\      _,,,---,,_
     /,`.-'`'    -.  ;-;;,_
    |,4-  ) )-,_..;\ (  `'-'
   '---''(_/--'  `-'\_)

   don't awake the admin cat
        </pre>
      </div>

    </ng-container>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="header-row content"></div>
    <div class="main">
      <div class="container">
        <div class="mini-nav" *ngIf="currentUser"
             [ngClass]="{ active: screenWidth <= 840}">
          <mat-icon mat-button (click)="sidenav.open()">dehaze</mat-icon>

          <mat-divider></mat-divider>
          <br />
        </div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

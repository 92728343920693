import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@saikin/auth/auth.guard';

import { EngravingEntryView } from './engraving.entry';

import { EngravingImagesComponent
       } from './engraving-images/engraving-images.component';

const routing: Routes = [
  {
    path: 'engraving',
    canActivate: [AuthGuard],
    component: EngravingEntryView,
    children: [
      {
        path: '',
        redirectTo: '/engraving/images',
        pathMatch: 'full'
      },
      {
        path: 'images',
        canActivate: [AuthGuard],
        component: EngravingImagesComponent,
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routing)],
  exports: [RouterModule]
})
export class EngravingRouting { }

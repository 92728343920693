import { NgModule } from '@angular/core';

import { AuthCanDoDirective } from '@saikin/auth/auth.directive';
import { AuthService } from '@saikin/auth/auth.service';

@NgModule({
  declarations: [
    AuthCanDoDirective,
  ],
  exports: [
    AuthCanDoDirective,
  ],
  providers: [
    AuthService,
  ]
})
export class AuthModule {}

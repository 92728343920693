import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { AuthModule } from '@saikin/auth/auth.module';
import { CnstModule } from '@saikin/cnst/cnst.module';
import { MatModule } from '@saikin/mat.module';

import { EngravingEntryView } from './engraving.entry';
import { EngravingRouting } from './engraving.routing';
import { EngravingService } from './engraving.service';

import { EngravingImagesComponent
       } from './engraving-images/engraving-images.component';

@NgModule({
    declarations: [
        EngravingEntryView,
        EngravingImagesComponent,
    ],
    exports: [
        EngravingEntryView,
    ],
    imports: [
        AuthModule,
        BrowserModule,
        CnstModule,
        FormsModule,
        MatModule,
        ReactiveFormsModule,
        RouterModule,
        EngravingRouting,
    ],
    providers: [
        EngravingService,
    ]
})
export class EngravingModule
{ }

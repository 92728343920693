import { CnstBaseModel, CnstModel } from '@saikin/cnst/cnst-model';

@CnstModel('Coupon')
export class Coupon extends CnstBaseModel
{
  code: string = '';
  amount: number = 0;
  active: boolean = true;
  templateGroup: string = 'default';
  template: string = 'default';
  invoiceId: number = 1;
  redeemDate: string = '';
}

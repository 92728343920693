import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@saikin/auth/auth.guard';

import { BillingEntryView } from './billing.entry';

import { EditInvoiceComponent } from './edit-invoice/edit-invoice.component';
import { InvoicesOverviewComponent
       } from './invoices-overview/invoices-overview.component';

const routing: Routes = [
  {
    path: 'billing',
    canActivate: [AuthGuard],
    component: BillingEntryView,
    children: [
      {
        path: '',
        redirectTo: '/billing/overview',
        pathMatch: 'full'
      },
      {
        path: 'overview',
        canActivate: [AuthGuard],
        component: InvoicesOverviewComponent,
      },
      {
        path: 'add',
        canActivate: [AuthGuard],
        component: EditInvoiceComponent
      },
      {
        path: 'edit/:id',
        canActivate: [AuthGuard],
        component: EditInvoiceComponent,
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routing)],
  exports: [RouterModule]
})
export class BillingRouting { }

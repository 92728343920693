import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({ selector: '[middleclick]' })
export class CnstMiddleClickDirective
{
  @Output() middleclick: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('mouseup', ['$event'])
  public middleclickEvent(event): void
  {
    if (event.which === 2) {
      this.middleclick.emit(event);
    }
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@saikin/auth/auth.service';
import { CnstModelStorage } from '@saikin/cnst/cnst-model';

import * as Models from '@config/models';
import { User } from '@config/models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit
{
  @ViewChild('sidenav', { static: true }) public sidenav: ElementRef;

  public currentUser: User;
  public logoUrl: string = undefined;

  public screenWidth: number;

  constructor(
    private elementRef: ElementRef,
    private router: Router,
    private authService: AuthService
  ) {
    this.authService
      .currentUserSubject.asObservable()
      .subscribe(async u => {
        this.currentUser = u;
      });

    window.onresize = () => { this.screenWidth = window.innerWidth; };

    // TODO: so schön ist das ja nicht ...
    const modelStorage = new CnstModelStorage();
    for (const modelName of Object.keys(Models)) {
      modelStorage.addModel(Models[modelName]);
    }
  }

  public async ngOnInit(): Promise<void>
  {
    this.screenWidth = window.innerWidth;
  }

  public logout(): void
  {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}

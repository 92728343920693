import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { AuthModule } from '@saikin/auth/auth.module';
import { CnstModule } from '@saikin/cnst/cnst.module';
import { MatModule } from '@saikin/mat.module';

import { ProductEntryView } from './product.entry';
import { ProductRouting } from './product.routing';

import { DeleteProductGroupDialog
       } from './edit-product-group/delete-product-group.dialog';
import { EditProductGroupComponent
       } from './edit-product-group/edit-product-group.component';
import { ProductGroupsOverviewComponent
       } from './product-groups-overview/product-groups-overview.component';
import { ProductGroupService } from './productgroup.service';

import { DeleteProductDialog } from './edit-product/delete-product.dialog';
import { EditProductComponent } from './edit-product/edit-product.component';
import { ProductService } from './product.service';

@NgModule({
    declarations: [
        ProductEntryView,
        DeleteProductGroupDialog,
        EditProductGroupComponent,
        ProductGroupsOverviewComponent,
        DeleteProductDialog,
        EditProductComponent,
    ],
    exports: [
        ProductEntryView,
    ],
    imports: [
        AuthModule,
        BrowserModule,
        CnstModule,
        FormsModule,
        MatModule,
        ReactiveFormsModule,
        RouterModule,
        ProductRouting,
    ],
    providers: [
        ProductGroupService,
        ProductService,
    ]
})
export class ProductModule
{ }

import { Component, ElementRef, EventEmitter, Input, OnChanges,
         Output, SimpleChange, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';

import { ImageService } from 'app/image.service';
import { Product, ProductImage } from '@config/models';
import { executeWithNotification, LoadingIndicator } from '@saikin/util';

import { ProductService } from '../product.service';
import { DeleteProductDialog } from './delete-product.dialog';

@Component({
  selector: 'edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: [ './edit-product.component.scss' ],
})
export class EditProductComponent implements OnChanges
{
  @Input() product: Product = undefined;
  @Input() groupId: string = undefined;
  @Output() removeProduct: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('productImage') public productImage: ElementRef;
  public imgUploadInProgress: LoadingIndicator = new LoadingIndicator();

  public dataSource: MatTableDataSource<ProductImage>;
  public loading: LoadingIndicator = new LoadingIndicator();

  constructor(
      private productService: ProductService,
      private imageService: ImageService,
      private snackBar: MatSnackBar,
      public dialog: MatDialog) { }

  public ngOnChanges(changes: {[propKey: string]: SimpleChange}): void
  {
    if (changes['product']) {
      this.product = changes['product'].currentValue;
    }
  }

  public async addImage(file: File): Promise<void>
  {
    const callback = async () => {
      this.product.images.push(
        await this.imageService.addImage(undefined, this.product.id, file)
      );
    };

    await executeWithNotification(this.snackBar, {
      callback,
      indicator: this.imgUploadInProgress,
      error: 'Hochladen fehlgeschlagen!',
    });

    this.productImage.nativeElement.value = '';
  }

  public async deleteImage(index: number): Promise<void>
  {
    const image = this.product.images[index];
    await this.imageService.deleteImage(image);
    this.product.images.splice(index, 1);
  }

  public async updateProduct(): Promise<void>
  {
    this.product.groupId = this.groupId;
    const callback = async () => await this.productService.update(this.product);

    executeWithNotification(this.snackBar, {
      callback,
      success: `Produkt [${this.product.id}] ${this.product.name} ` +
               `erfolgreich bearbeitet.`,
      error: 'Bearbeiten fehlgeschlagen!',
    });
  }

  public async addProduct(): Promise<void>
  {
    this.product.groupId = this.groupId;
    const callback = async () => await this.productService.create(this.product);

    await executeWithNotification(this.snackBar, {
      callback,
      indicator: this.loading,
      success: `Produkt ${this.product.name} erfolgreich angelegt.`,
      error: 'Anlegen fehlgeschlagen!',
    });
  }

  public openDeleteProductDialog(): void
  {
    const dialogRef = this.dialog.open(DeleteProductDialog, {
      data: { product: this.product }
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result !== undefined) {
        await this.productService.delete(this.groupId, this.product);
        this.removeProduct.emit();
      }
    });
  }

  public remove(): void
  {
    this.removeProduct.emit();
  }
}

<h3 class="primary">Übersicht Gutscheine</h3>
<section>
  <mat-form-field>
    <input matInput (keyup)="filterValue = $event.target.value; applyFilter()"
           placeholder="Filter"
           name="invoiceing_overview_filter">
  </mat-form-field>

  <br />

  <button mat-raised-button
          (click)="downloadAsPdf()"
          [attr.data-action]="'download-as-pdf'">
    <mat-icon [style.color]="'red'">picture_as_pdf</mat-icon>
    als PDF downloaden
  </button>
</section>

<section>
  <img *ngIf="loading.state" [src]="loading.imagePath" />

  <div *ngIf="dataSource !== undefined">
    <mat-table [dataSource]="dataSource"
               matSort (matSortChange)="sortData($event)">

      <ng-container matColumnDef="code">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Gutschein-Code.
        </mat-header-cell>
        <mat-cell *matCellDef="let coupon">{{ coupon.code }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="amount">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Betrag
        </mat-header-cell>
        <mat-cell *matCellDef="let coupon">
          {{ coupon.amount | currency:'EUR':'symbol':'0.2-2':'de' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="created">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Gekauft
        </mat-header-cell>
        <mat-cell *matCellDef="let coupon">
          {{ coupon.created | date: 'dd.MM.yyyy' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="active">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          eingelöst
        </mat-header-cell>
        <mat-cell *matCellDef="let coupon">
          <span *ngIf="!coupon.active">
            eingelöst am {{ coupon.redeemDate | date: 'dd.MM.yyyy' }}
          </span>
          <button mat-flat-button color="primary" *ngIf="coupon.active"
                             (click)="invalidateCoupon(coupon)">
            Gutschein einlösen
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="['code', 'amount', 'created', 'active']"
      ></mat-header-row>
      <mat-row *matRowDef="let product;
                           columns: ['code', 'amount', 'created', 'active' ];">
      </mat-row>
    </mat-table>
    <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"
    ></mat-paginator>
  </div>
</section>

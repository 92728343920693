import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Coupon } from '@config/models';
import { accessBlobUrl, LoadingIndicator, executeWithNotification
       } from '@saikin/util';

import { CouponService } from '../coupon.service';

@Component({
  selector: 'coupons-overview',
  templateUrl: './coupons-overview.component.html',
  styleUrls: ['./coupons-overview.component.scss']
})
export class CouponsOverviewComponent implements OnInit
{
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public filterValue: string = '';

  private coupons: Array<Coupon> = [];
  public dataSource: MatTableDataSource<Coupon>;

  public loading: LoadingIndicator = new LoadingIndicator();

  constructor(
    private couponService: CouponService,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef
  ) { }

  public async ngOnInit(): Promise<void>
  {
    this.loading.state = true;
    this.coupons = await this.couponService.getAll();

    this.createDataSource(this.coupons);
    this.loading.state = false;
  }

  public sortData(sort: Sort): void
  {
    let data = this.coupons.slice();
    if (sort.active && sort.direction !== '') {
      const isAsc = sort.direction === 'asc';
      const compare = (a, b) => {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
      };

      data = data.sort((a, b) => {
        switch (sort.active) {
          case 'code': return compare(a.code, b.code);
          case 'created': return compare(a.created, b.created);
          case 'amount': return compare(a.amount, b.amount);
          case 'active': return compare(a.modified, b.modified);
          default: return 0;
        }
      });
    }

    this.createDataSource(data);
    this.applyFilter();
  }

  public applyFilter(): void
  {
    const filterValue = this.filterValue.trim().toLowerCase();
    this.dataSource.filter = filterValue;
  }

  private createDataSource(data: Array<Coupon>): void
  {
    this.dataSource = new MatTableDataSource(data);
    this.cdr.detectChanges();
    this.dataSource.paginator = this.paginator;
  }

  public async invalidateCoupon(coupon: Coupon): Promise<void>
  {
    const callback = async () => {
      coupon.active = false;
      coupon = await this.couponService.update(coupon);
    };

    executeWithNotification(this.snackBar, {
      callback,
      success: 'Gutschein wurde eingelöst.',
      error: 'Gutschein konnte nicht eingelöst werden!',
    });
  }

  public async downloadAsPdf(): Promise<void>
  {
    try {
      accessBlobUrl(await this.couponService.getPdf(), 'Gutscheinliste.pdf');
    }
    catch (error) {
      console.error(error);
    }
  }
}

<h3 class="primary">Übersicht Rechnungen</h3>
<section>
  <mat-form-field>
    <input matInput (keyup)="filterValue = $event.target.value; applyFilter()"
           placeholder="Filter"
           name="invoiceing_overview_filter">
  </mat-form-field>

  <br />

  <a [routerLink]="['/billing/add']"
     [attr.data-action]="'create-new-invoice'">
    <button mat-raised-button>neue Rechnung anlegen</button>
  </a>

</section>

<section>
  <img *ngIf="loading.state" [src]="loading.imagePath" />

  <div *ngIf="dataSource !== undefined">
    <mat-table [dataSource]="dataSource"
               matSort (matSortChange)="sortData($event)">

      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Rechnungs-Nr.
        </mat-header-cell>
        <mat-cell *matCellDef="let invoice">
          <a [routerLink]="['/billing/edit', invoice.id]">
            {{ invoice.billNumber }}
          </a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Name / Firma / Verein
        </mat-header-cell>
        <mat-cell *matCellDef="let invoice">
          <a [routerLink]="['/billing/edit', invoice.id]">
            {{ invoice.address.getName() }}
          </a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="created">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Rechnungsdatum
        </mat-header-cell>
        <mat-cell *matCellDef="let invoice">
          <span *ngIf="invoice._isCancelled"
                style="color: red; font-weight:bold">
            storniert
          </span>

          <span *ngIf="!invoice._isCancelled">
            {{ invoice.invoiceDate | date: 'dd.MM.yyyy' }}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="payed">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          bezahlt
        </mat-header-cell>
        <mat-cell *matCellDef="let invoice">
          <span *ngIf="invoice.payedDate">
            {{ invoice.payedDate | date: 'dd.MM.yyyy' }}
          </span>
          <span *ngIf="!invoice.payedDate">-</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="total">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Summe
        </mat-header-cell>
        <mat-cell *matCellDef="let invoice">
          {{ invoice._total  | currency:'EUR':'symbol':'0.2-2':'de' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="options">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let invoice">
          <ng-container *ngIf="!invoice._isCancelled">
            <button mat-button (click)="downloadPDF(invoice, true)">
              <mat-icon [style.color]="'red'">picture_as_pdf</mat-icon>
            </button>

            <button mat-button (click)="downloadPDF(invoice, false)">
              <mat-icon>content_copy</mat-icon>
            </button>
          </ng-container>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="['id', 'name', 'created', 'payed', 'total',
                           'options']"
      ></mat-header-row>
      <mat-row *matRowDef="let product;
                           columns: ['id', 'name', 'created', 'payed', 'total',
                                     'options'];">
      </mat-row>
    </mat-table>
    <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"
    ></mat-paginator>
  </div>
</section>

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ProductGroup } from '@config/models';

export interface DialogData {
  group: ProductGroup;
}

@Component({
  selector: 'delete-product-group-dialog',
  templateUrl: 'delete-product-group.dialog.html',
})
export class DeleteProductGroupDialog
{
  public productGroup: ProductGroup;

  constructor(
    public dialogRef: MatDialogRef<DeleteProductGroupDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  )
  {
    this.productGroup = data.group;
  }

  public cancel(): void
  {
    this.dialogRef.close();
  }
}

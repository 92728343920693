<button mat-raised-button color="secondary" routerLink="/products"
        class="button-back">
  Zurück
</button>

<ng-container *ngIf="productGroup">

  <h3 class="primary" *ngIf="productGroup.id">{{productGroup.name}} bearbeiten</h3>
  <h3 class="primary" *ngIf="!productGroup.id">Produktgruppe anlegen</h3>
  <section>
    <div class="props">
      <table class="basics">
        <tr>
          <td>
            <mat-form-field>
              <mat-label>Bezeichnung</mat-label>
              <input matInput placeholder="Bezeichnung"
                     name="product_group_name"
                     [(ngModel)]="productGroup.name">
            </mat-form-field>
          </td>

          <td>
            <mat-form-field>
              <mat-label>Gruppen-Typ</mat-label>
              <mat-select [(value)]="productGroup.type">
                <mat-option *ngFor="let type of types" [value]="type">
                  {{ type }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>

          <td>
            <mat-checkbox
              [checked]="productGroup.isActive"
              (change)="productGroup.isActive = $event.checked"
              name="product_group_is_active"
            >ist aktiv</mat-checkbox>
          </td>
        </tr>

        <tr>
          <td colspan="3">
            <mat-form-field class="extended">
              <mat-label>Werbespruch / Schlagwort</mat-label>
              <input matInput placeholder="Werbespruch"
                     name="product_group_catchphrase"
                     [(ngModel)]="productGroup.catchphrase">
            </mat-form-field>
          </td>
        </tr>

        <tr>
          <td colspan="3">
            <mat-form-field appearance="fill" class="extended">
              <mat-label>Beschreibungstext</mat-label>
              <textarea matInput [(ngModel)]="productGroup.description"
              ></textarea>
            </mat-form-field>
          </td>
        </tr>
      </table>


      <div class="image-data">
        <strong>Bild</strong><br />
        <ng-container *ngIf="productGroup.image && productGroup.image.path">
          <a [href]="productGroup.image.path" target="blank">
            <img [src]="productGroup.image.thumbnail" />
          </a>
          <small (click)="deleteImage()">entfernen</small>
        </ng-container>

        <ng-container *ngIf="!productGroup.image || !productGroup.image.path">
          <img *ngIf="!imgUploadInProgress.state"
               src="assets/images/add-image.png"
               (click)="productImage.click()" />
          <mat-progress-spinner *ngIf="imgUploadInProgress.state"
                                mode="indeterminate"
          ></mat-progress-spinner>
          <input type="file" id="productImage" #productImage
                 style="display: none"
                 name="productImage" accept="image/*"
                 (change)="addImage($event.target.files[0])">
        </ng-container>
      </div>
    </div>
  </section>
  <section>
    <cnst-button-section *ngIf="productGroup"
      [objectId]="productGroup.id" [prefix]="'Produktgruppe'"

      (add)="create();"
      (edit)="update()"
      (delete)="openDeleteDialog()"
    ></cnst-button-section>
  </section>

  <ng-container *ngIf="productGroup.id">
    <h3 class="secondary">Produkte</h3>
    <section>
      <ng-container *ngFor="let product of productGroup.products; let i = index">
        <edit-product [groupId]="productGroup.id"
                      [product]="product"
                      (removeProduct)="removeProduct(i)"
        ></edit-product>
      </ng-container>

      <br />

      <button mat-raised-button color="primary" (click)="addProduct()"
              [attr.data-action]="'add-product'">
        Produkt hinzufügen
      </button>
    </section>
  </ng-container>
</ng-container>



import { Component, ElementRef, EventEmitter, HostListener, OnInit,
         Output, ViewChild
       } from '@angular/core';

interface Bounds
{
  x: number;
  y: number;
}

@Component({
  selector: 'cnst-signature-pad',
  template: `
    <div style="width: 100%;">
      <canvas #signaturePad height="150"
              [style.display]="isDone ? 'hidden' : 'inherit'"
              (mousedown)="onMouseDown($event)"
              (mousemove)="onMouseMove($event)"
      ></canvas>
      <img *ngIf="isDone" [src]="imgSrc" width="300px">
      <ng-container *ngIf="!isDone">
        <br/>
        <button mat-flat-button color="secondary" (click)="clear()"
        >leeren</button>
        <button mat-flat-button color="primary" (click)="save()"
        >OK</button>
      </ng-container>
    </div>
    `,
  styles: [ `
    canvas { border: 1px solid #000; }
  `]
})
export class CnstSignaturePadComponent implements OnInit
{
  @Output() signatureDone: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('signaturePad', { static: true }) signaturePad: ElementRef;
  private context: CanvasRenderingContext2D;
  private isDrawing: boolean = false;
  public isDone: boolean = false;
  public imgSrc: string;

  public ngOnInit(): void
  {
    this.context = this.signaturePad.nativeElement.getContext('2d');
    this.context.strokeStyle = '#3742fa';

    const resizeCanvas = () => {
      const parent = this.signaturePad.nativeElement.parentElement;
      this.signaturePad.nativeElement.width =
        parent.getBoundingClientRect().width;
    };

    window.addEventListener('resize', resizeCanvas, false);
    resizeCanvas();
  }

  @HostListener('document:mouseup', ['$event'])
  public onMouseUp(e): void
  {
    this.isDrawing = false;
  }

  public onMouseDown(e): void
  {
    this.isDrawing = true;
    const coords = this.relativeCoords(e);
    this.context.moveTo(coords.x, coords.y);
  }

  public onMouseMove(e): void
  {
    if (this.isDrawing) {
      const coords = this.relativeCoords(e);
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
    }
  }

  private relativeCoords(event): Bounds
  {
    const bounds = event.target.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    return { x, y };
  }

  public clear(): void
  {
    const element = this.signaturePad.nativeElement;
    this.context.clearRect(0, 0, element.width, element.height);
    this.context.beginPath();
  }

  public save(): void
  {
    const data = this.signaturePad.nativeElement.toDataURL('image/png');
    this.imgSrc = data;
    this.signatureDone.emit(data);
    this.isDone = true;
  }
}

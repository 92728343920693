import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Product } from '../product';

export interface DialogData {
  product: Product;
}

@Component({
  selector: 'delete-product-dialog',
  templateUrl: 'delete-product.dialog.html',
})
export class DeleteProductDialog
{
  public product: Product;

  constructor(
    public dialogRef: MatDialogRef<DeleteProductDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  )
  {
    this.product = data.product;
  }

  public cancel(): void
  {
    this.dialogRef.close();
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

import { ImageService } from 'app/image.service';
import { Product, ProductGroup } from '@config/models';
import { executeWithNotification, LoadingIndicator } from '@saikin/util';
import { DeleteProductGroupDialog } from './delete-product-group.dialog';

import { ProductGroupService } from '../productgroup.service';

@Component({
  selector: 'edit-product-group',
  templateUrl: './edit-product-group.component.html',
  styleUrls: [ './edit-product-group.component.scss' ],
})
export class EditProductGroupComponent implements OnInit
{
  public productGroup: ProductGroup = undefined;
  public loading: LoadingIndicator = new LoadingIndicator();
  public types: Array<string> = ['saisonal', 'general'];

  @ViewChild('productImage') public productImage: ElementRef;
  public imgUploadInProgress: LoadingIndicator = new LoadingIndicator();

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private productGroupService: ProductGroupService,
      private imageService: ImageService,
      private snackBar: MatSnackBar,
      public dialog: MatDialog) { }

  public async ngOnInit(): Promise<void>
  {
    this.productGroup = new ProductGroup();
    const groupId = this.route.snapshot.paramMap.get('id');
    if (groupId) {
      this.productGroup = await this.productGroupService.getById(groupId);
    }
  }

  public async create(): Promise<void>
  {
    const callback = async () => {
      this.productGroup =
        await this.productGroupService.create(this.productGroup);
    };

    executeWithNotification(this.snackBar, {
      callback,
      success: `Produktgruppe "${this.productGroup.name}" ` +
               `erfolgreich angelegt.`,
      error: 'Anlegen fehlgeschlagen!',
    });
  }

  public async update(): Promise<void>
  {
    const callback = async () => {
      this.productGroup =
        await this.productGroupService.update(this.productGroup);
    };

    executeWithNotification(this.snackBar, {
      callback,
      success: `Produktgruppe "${this.productGroup.name}" ` +
               `erfolgreich bearbeitet.`,
      error: 'Bearbeiten fehlgeschlagen!',
    });
  }

  public async addImage(file: File): Promise<void>
  {
    const callback = async () => {
      this.productGroup.image =
          await this.imageService.addImage(
              this.productGroup.id, undefined, file);
    };

    await executeWithNotification(this.snackBar, {
      callback,
      indicator: this.imgUploadInProgress,
      error: 'Hochladen fehlgeschlagen!',
    });

    this.productImage.nativeElement.value = '';
  }

  public async deleteImage(): Promise<void>
  {
    await this.imageService.deleteImage(this.productGroup.image);
    this.productGroup.image = undefined;
  }

  public openDeleteDialog(): void
  {
    const dialogRef = this.dialog.open(DeleteProductGroupDialog, {
      data: { group: this.productGroup }
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result !== undefined) {
        await this.productGroupService.delete(this.productGroup);
        this.router.navigate(['/products/overview']);
      }
    });
  }

  public addProduct(): void
  {
    this.productGroup.products.push(new Product());
  }

  public removeProduct(index: number): void
  {
    this.productGroup.products.splice(index, 1);
  }
}

<h3 class="primary" >Bildergalerie</h3>
<section>
  <div class="image" *ngFor="let image of images; let idx = index">
    <a [href]="image.path" target="blank">
      <img [src]="image.path.replace('.jpg', '.th.jpg')" />
    </a><br />
    <small (click)="deleteImage(idx)">entfernen</small>
  </div>

  <div class="image">
    <img *ngIf="!imgUploadInProgress.state"
         src="assets/images/add-image.png"
         (click)="newImage.click()" />
    <mat-progress-spinner *ngIf="imgUploadInProgress.state"
                          mode="indeterminate"
    ></mat-progress-spinner>
    <input type="file" id="newImage" #newImage
           style="display: none"
           name="engravingImage" accept="image/*"
           (change)="addImage($event.target.files[0])">
  </div>
</section>

import { CnstBaseModel, CnstModel } from '@saikin/cnst/cnst-model';

@CnstModel('Address')
export class Address extends CnstBaseModel
{
  company: string = '';
  firstName: string = '';
  lastName: string = '';
  street: string = '';
  addendum: string = '';
  postcode: string = '';
  city: string = '';
  countryCode: string = 'DEU';
  genderId: number = 1;

  public getName()
  {
    const name = [ this.lastName, this.firstName].join(' ').trim();

    return name.length > 0
      ? (this.company.length > 0
        ? [this.company, name].join(' | ') : name)
      : this.company;
  }
}

@CnstModel('InvoiceItem')
export class InvoiceItem extends CnstBaseModel
{
  public id: string = '';
  public label: string = '';
  public amount: number = 1;
  public price: number = 0;
  public taxRate: number = 0;
  public productData: number = 0;
}

@CnstModel('Invoice')
export class Invoice extends CnstBaseModel
{
  public id: string = '';
  public billNumber: string = '';

  public invoiceDate: string = '';
  public payedDate: string = '';

  public email: string = '';
  public paymentType: string = '';


  @CnstModel('InvoiceItem')
  public items: Array<InvoiceItem> = [];

  @CnstModel('Address')
  public address: Address = undefined;

  public _total?: number;
  public _isCancelled: boolean = false;

  public static fromResponse(json: any): Invoice
  {
    const instance = super.fromResponse(json);
    if (!instance.payedDate || instance.payedDate.startsWith('0000')) {
      instance.payedDate = undefined;
    }
    if (instance.paymentType === 'cancelled') {
      instance._isCancelled = true;
    }

    return instance;
  }
}

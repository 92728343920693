import { CnstService } from '@saikin/cnst/cnst.service';

import { ProductImage } from '@config/models';
import { Injectable } from "@angular/core";

@Injectable()
export class ImageService extends CnstService
{
  public async addImage(productGroupId: string, productId: string, file: File):
    Promise<ProductImage>
  {
    const formData = new FormData();
    formData.append('image_file', file);
    formData.append('product_id', productId);
    formData.append('product_group_id', productGroupId);

    const headers = {
      Authorization: 'Bearer ' + this.authService.token,
    };

    const response =
      await this.http.post('/images', formData, headers);

    return ProductImage.fromResponse(response.body);
  }

  public async deleteImage(image: ProductImage): Promise<void>
  {
    const headers = {
        ...this.getHeaders(),
        'If-Match': image.path
    };

    if (image.id) {
      await this.http.delete('/images/' + image.id, headers);
    }
    else {
      await this.http.delete('/images/null', headers);
    }
  }
}

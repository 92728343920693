import { Component, OnInit } from '@angular/core';

import { LoadingIndicator } from '@saikin/util';
import { Invoice } from '@config/models';

import { BillingService } from 'app/billing/billing.service';
import { ProductGroupService } from 'app/product/productgroup.service';

@Component({
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit
{
  public groups: Array<any> = [];
  public openBills: Array<Invoice> = [];

  public loading: LoadingIndicator = new LoadingIndicator();

  constructor(
    private productGroupService: ProductGroupService,
    private billingService: BillingService
  )
  { }

  public async ngOnInit(): Promise<void>
  {
    this.loading.state = true;
    this.productGroupService.getAll().then((groups) => {
      const _groups = {}

      for (const group of groups) {
        if (!_groups[group.type]) {
          _groups[group.type] = {
            active: 0,
            inactive: 0
          }
        }
        _groups[group.type][group.isActive ? 'active' : 'inactive'] ++;
      }

      for (let groupName of Object.keys(_groups)) {
        this.groups.push({
            name: groupName,
            active: _groups[groupName].active,
            inactive: _groups[groupName].inactive
        });
      }
      console.log(this.groups);
      this.loading.state = false;
    });

    this.billingService.getAll().then((bills) => {
      this.openBills = bills.filter(b => !b.payedDate && !b._isCancelled);
      this.loading.state = false;
    });
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ProductImage } from '@config/models';
import { executeWithNotification, LoadingIndicator } from '@saikin/util';

import { ImageService } from 'app/image.service';
import { EngravingService } from '../engraving.service';

@Component({
  selector: 'engraving-images',
  templateUrl: './engraving-images.component.html',
  styleUrls: [ './engraving-images.component.scss' ],
})
export class EngravingImagesComponent implements OnInit
{
  @ViewChild('newImage', { static: true }) public newImage: ElementRef;
  public imgUploadInProgress: LoadingIndicator = new LoadingIndicator();

  public images: Array<ProductImage> = [];

  constructor(
      private engravingService: EngravingService,
      private imageService: ImageService,
      private snackBar: MatSnackBar,
      public dialog: MatDialog) { }

  public async ngOnInit(): Promise<void>
  {
    this.images = await this.engravingService.getAllImages();
  }

  public async addImage(file: File): Promise<void>
  {
    const callback = async () => {
      this.images.push(
        await this.imageService.addImage(undefined, '1', file)
      );
    };

    await executeWithNotification(this.snackBar, {
      callback,
      indicator: this.imgUploadInProgress,
      error: 'Hochladen fehlgeschlagen!',
    });

    this.newImage.nativeElement.value = '';
  }

  public async deleteImage(index: number): Promise<void>
  {
    const image = this.images[index];
    await this.imageService.deleteImage(image);
    this.images.splice(index, 1);
  }
}

import { permissionsList } from '@config/permissions';
import { NavigationItem } from '@saikin/navigation/navigation.model';

export const topnav: Array<NavigationItem> = [];

export const sidenav: Array<NavigationItem> = [
  {
    label: 'Übersicht',
    link: '/',
    icon: 'dashboard',
  },
  {
    label: 'Produkte',
    link: '/products/overview',
    icon: 'toys',
  },
  {
    label: 'Bilder (Gravuren)',
    link: '/engraving/images',
    icon: 'stars',
  },
  {
    label: 'Rechnungen',
    link: '/billing/overview',
    icon: 'attach_money',
  },
  {
    label: 'Gutscheine',
    link: '/coupons/overview',
    icon: 'attach_file',
  },
];

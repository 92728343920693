import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';

import { executeWithNotification, LoadingIndicator } from '@saikin/util';
import { AuthService } from '@saikin/auth/auth.service';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit
{
  public loginForm: UntypedFormGroup;
  public loading: LoadingIndicator = new LoadingIndicator();
  public submitted: boolean = false;
  public error: boolean = false;

  public logoUrl: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private snackBar: MatSnackBar
  )
  {
    // redirect to dashbboard if already logged in
    if (this.authService.currentUser) {
      this.router.navigate(['/']);
    }

    const token = this.route.snapshot.queryParamMap.get('token');
    if (token) {
      const callback = () => this.loginWithToken(token);
      executeWithNotification(this.snackBar, {
        callback,
        error: 'Session abgelaufen!',
      });
    }
  }

  public async loginWithToken(token: string): Promise<void>
  {
    const data = token.split('.');
    data.shift();
    await this.authService.loginWithToken(data.join('.'));
    this.router.navigate(['/home']);
  }

  public ngOnInit(): void
  {
    this.loginForm = this.formBuilder.group({
        username: ['', Validators.required],
        password: ['', Validators.required]
    });
  }

  // convenience getter for easy access to form fields
  public get f(): any
  {
    return this.loginForm.controls;
  }

  public async onSubmit(): Promise<void>
  {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    const callback = async () => {
      await this.authService
        .login(this.f.username.value, this.f.password.value);
      this.router.navigate(['/']);
    };

    executeWithNotification(this.snackBar, {
      callback,
      indicator: this.loading,
      error: 'Anmeldung fehlgeschlagen!',
    });
  }
}

<button mat-raised-button color="secondary" routerLink="/billing"
        class="button-back">
  Zurück
</button>

<ng-container *ngIf="invoice">
  <h3 class="primary justify-content">
    <span>
      Rechnung
      <span *ngIf="invoice.id">{{invoice.billNumber}} bearbeiten</span>
      <span *ngIf="!invoice.id">Rechnung anlegen</span>
    </span>
    <mat-slide-toggle color="warn" [(ngModel)]="readOnly">
      Rechnung sperren
    </mat-slide-toggle>
  </h3>

  <h3 class="secondary">Kundenadresse</h3>
  <section>
    <cnst-auto-combobox
        *ngIf="!readOnly"
        [data]="addresses"
        [displayField]="'getName'"
        [placeholder]="'Kundenadresse'"
        (selected)="addressSelected($event)"
    ></cnst-auto-combobox>

    <table>
      <tr>
        <th>Typ</th>
        <td *ngIf="readOnly">
          {{ invoice.address.genderId === 0
              ? 'Firma / Verein'
              : 'Privatperson' }}
        </td>
        <td *ngIf="!readOnly">
          <mat-form-field>
            <mat-select [(value)]="isPrivatePerson"
                        (selectionChange)="invoice.address.genderId = (
                            $event.value === true ? '1' : 0) ">
              <mat-option [value]="true">Privatperson</mat-option>
              <mat-option [value]="false">Firma / Verein</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td>
          <ng-container *ngIf="!readOnly && invoice.address.genderId === 0">
            <mat-checkbox
                [checked]="withRefPerson"
                (change)="withRefPerson = $event.checked"
                name="product_group_is_active"
            >Mit Ansprechpartner</mat-checkbox>
          </ng-container>
        </td>
      </tr>
      <tr>
        <th>E-Mail</th>
        <td *ngIf="readOnly">
          {{ invoice.email }}
        </td>
        <td *ngIf="!readOnly">
          <mat-form-field>
            <input matInput placeholder="E-Mail"
                   name="invoice_email"
                   [(ngModel)]="invoice.email">
          </mat-form-field>
        </td>
        <td></td>
      </tr>

      <tr><th colspan="3"><hr /></th></tr>

      <tr *ngIf="!readOnly && invoice.address.genderId > 0">
        <th>Anrede</th>
        <td>
          <mat-form-field *ngIf="isPrivatePerson">
            <mat-select [(value)]="invoice.address.genderId">
              <mat-option [value]="1">Herr</mat-option>
              <mat-option [value]="2">Frau</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td></td>
      </tr>

      <tr *ngIf="invoice.address.genderId === 0 ? withRefPerson : true">
        <th *ngIf="invoice.address.genderId === 0">Ansprechpartner</th>
        <th *ngIf="invoice.address.genderId > 0">Name</th>
        <td *ngIf="readOnly">
          <span *ngIf="invoice.address.genderId > 0">
            {{ invoice.address.genderId === 1 ? 'Herr' : 'Frau' }}
          </span>
          {{ invoice.address.firstName }}
          {{ invoice.address.lastName }}
        </td>

        <td *ngIf="!readOnly">
          <mat-form-field>
            <input matInput placeholder="Vorname"
                   name="invoice_address_firstname"
                   [(ngModel)]="invoice.address.firstName">
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Name"
                   name="invoice_address_lastname"
                   [(ngModel)]="invoice.address.lastName">
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <th>Firma</th>
        <td *ngIf="readOnly">
          {{ invoice.address.company }}
        </td>
        <td *ngIf="!readOnly">
          <mat-form-field>
            <input matInput placeholder="Firma"
                   name="invoice_address_company"
                   [(ngModel)]="invoice.address.company">
          </mat-form-field>
        </td>
        <td></td><td></td>
      </tr>
      <tr>
        <th>Zusatz</th>
        <td *ngIf="readOnly">
          {{ invoice.address.addendum }}
        </td>
        <td *ngIf="!readOnly">
          <mat-form-field>
            <input matInput placeholder="Zusatz"
                   name="invoice_address_addendum"
                   [(ngModel)]="invoice.address.addendum">
          </mat-form-field>
        </td>
        <td></td><td></td>
      </tr>
      <tr>
        <th>Straße</th>
        <td *ngIf="readOnly">
          {{ invoice.address.street }}
        </td>
        <td *ngIf="!readOnly">
          <mat-form-field>
            <input matInput placeholder="Straße, Hausnummer"
                   name="invoice_address_street"
                   [(ngModel)]="invoice.address.street">
          </mat-form-field>
        </td>
        <td></td><td></td>
      </tr>
      <tr>
        <th>Ort</th>
        <td *ngIf="readOnly">
          {{ invoice.address.postcode }}
          {{ invoice.address.city }}
        </td>
        <td *ngIf="!readOnly">
          <mat-form-field>
            <input matInput placeholder="Postleitzahl"
                   name="invoice_address_postcode"
                   [(ngModel)]="invoice.address.postcode">
          </mat-form-field>

          <mat-form-field>
            <input matInput placeholder="Ort"
                   name="invoice_address_city"
                   [(ngModel)]="invoice.address.city">
          </mat-form-field>
        </td>
        <td></td>
      </tr>
    </table>
  </section>

  <h3 class="secondary">Rechnungsdaten</h3>
  <section>
    <table>
      <tr>
        <th>Rechnungsnummer</th>
        <td *ngIf="readOnly">
          {{ invoice.billNumber }}
        </td>
        <td *ngIf="!readOnly">
          <mat-form-field>
            <input matInput placeholder="Rechnungsnummer"
                   name="invoice_number"
                   [(ngModel)]="invoice.billNumber">
          </mat-form-field>
        </td>
      </tr>

      <tr>
        <th>Rechnungsdatum</th>
        <td *ngIf="readOnly">
          {{ invoice.invoiceDate | date: 'dd.MM.yyyy' }}
        </td>
        <td *ngIf="!readOnly">
          <mat-form-field>
            <input matInput [matDatepicker]="invoiceDateDP"
                placeholder="Rechnungsdatum"
                [(ngModel)]="invoiceDate"
                (dateChange)="invoice.invoiceDate = formatDate($event.value)"
            >
            <mat-datepicker-toggle matSuffix [for]="invoiceDateDP"
            ></mat-datepicker-toggle>
            <mat-datepicker #invoiceDateDP [startAt]="currentDate"
            ></mat-datepicker>
          </mat-form-field>
        </td>
      </tr>

      <tr>
        <th>bezahlt am</th>
        <td *ngIf="invoice.payedDate">
          {{ invoice.payedDate | date: 'dd.MM.yyyy' }}
        </td>
        <td *ngIf="!invoice.payedDate">
          <mat-form-field>
            <input matInput [matDatepicker]="payedDateDP"
                placeholder="Zahlungsdatum"
                [(ngModel)]="payedDate"
                (dateChange)="invoice.payedDate = formatDate($event.value)"
            >
            <mat-datepicker-toggle matSuffix [for]="payedDateDP"
            ></mat-datepicker-toggle>
            <mat-datepicker #payedDateDP [startAt]="currentDate"
            ></mat-datepicker>
          </mat-form-field>
        </td>
      </tr>

      <tr>
        <th>Zahlungsmethode</th>
        <td *ngIf="readOnly">
          {{ selectedPaymentMethod }}
        </td>
        <td *ngIf="!readOnly">
          <mat-form-field>
            <mat-select [(value)]="invoice.paymentType">
              <mat-option *ngFor="let pm of paymentMethods"
                          [value]="pm.value">{{ pm.text }}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td></td>
      </tr>
    </table>
  </section>

  <h3 class="secondary">Rechnungspositionen</h3>
  <section>
    <div class="mat-elevation-z8" *ngIf="dataSource !== undefined">
      <mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="label">
          <mat-header-cell *matHeaderCellDef>Bezeichnung</mat-header-cell>
          <mat-cell *matCellDef="let item">
            <ng-container *ngIf="readOnly">
              <span *ngIf="!item.productData">{{ item.label }}</span>
              <span *ngIf="item.productData"
                    (click)="getItemData(item)"
                    style="text-decoration: underline; cursor: pointer"
              >{{ item.label }}</span>
            </ng-container>

            <mat-form-field *ngIf="!readOnly">
              <input matInput name="item_label"
                     (focus)="$event.target.select()"
                     [(ngModel)]="item.label">
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef>Anzahl</mat-header-cell>
          <mat-cell *matCellDef="let item">
            <span *ngIf="readOnly">{{ item.amount }}</span>

            <mat-form-field *ngIf="!readOnly">
              <input matInput name="item_amount" type="number"
                     [(ngModel)]="item.amount"
                     (focus)="$event.target.select()"
                     (change)="updateTotalSum()">
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="price">
          <mat-header-cell *matHeaderCellDef>Einzelpreis</mat-header-cell>
          <mat-cell *matCellDef="let item">
            <span *ngIf="readOnly">
              {{ item.price | currency:'EUR':'symbol':'0.2-2':'de'  }}
            </span>

            <mat-form-field *ngIf="!readOnly">
              <input matInput name="item_price" type="number"
                     [(ngModel)]="item.price"
                     (focus)="$event.target.select()"
                     (change)="updateTotalSum()">
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tax">
          <mat-header-cell *matHeaderCellDef>Steuersatz</mat-header-cell>
          <mat-cell *matCellDef="let item">
            <mat-form-field>
              <mat-select [(value)]="item.taxRate"
                          [disabled]="readOnly"
                          (change)="updateTotalSum()">
                <mat-option [value]="settings.taxRate.default">
                  Standard ({{ settings.taxRate.default }}%)
                </mat-option>
                <mat-option [value]="settings.taxRate.reduced">
                  ermäßigt ({{ settings.taxRate.reduced }}%)
                </mat-option>
                <mat-option [value]="0">
                  ohne ({{ 0 }}%)
                </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="itemTotal">
          <mat-header-cell *matHeaderCellDef>Gesamt</mat-header-cell>
          <mat-cell *matCellDef="let item">
            {{ (item.amount * item.price) |
               currency:'EUR':'symbol':'0.2-2':'de'  }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="remove">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let item; let idx = index">
            <button (click)="removeInvoiceItem(idx)"
                    mat-raised-button>entfernen</button>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="['label', 'amount', 'price', 'tax',
                             readOnly ? 'itemTotal' : 'remove']"
        ></mat-header-row>
        <mat-row *matRowDef="let product;
                             columns: ['label', 'amount', 'price', 'tax',
                                       readOnly ? 'itemTotal' : 'remove'];">
        </mat-row>
      </mat-table>
    </div>

    <hr />
    <button *ngIf="!readOnly"
            mat-raised-button  (click)="addInvoiceItem()"
            [attr.data-action]="'add-item'">
      Position hinzufügen
    </button>

    <div style="float: right; margin-right: 10px;">
      {{ totalSum | currency:'EUR':'symbol':'0.2-2':'de' }}
    </div>
  </section>

  <section style="display: flex; justify-content: space-between">
    <cnst-button-section
      [objectId]="invoice.id" [prefix]="'Rechnung'"
      [buttons]="['add', 'edit']"

      (add)="create();"
      (edit)="update()"
    ></cnst-button-section>
    <button *ngIf="!readOnly && invoice.id"
            color="warn"
            mat-raised-button  (click)="cancelInvoice()"
            [attr.data-action]="'cancel-invoice'">
      Rechnung stornieren
    </button>
  </section>
  <section>
    <button mat-raised-button *ngIf="isVoucherInvoice"
            (click)="sendVoucherEmail()">
      Gutschein-E-Mail versenden
    </button>
  </section>
</ng-container>



import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { MatModule } from '@saikin/mat.module';

import { CnstAutoComboboxComponent
       } from './auto-combobox/auto-combobox.component';
import { CnstButtonSectionComponent
       } from './button-section/button-section.component';
import { CnstSignaturePadComponent
       } from './signature-pad/signature-pad.component';

import { CnstMiddleClickDirective } from './middleclick/middleclick.directive';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    MatModule,
    ReactiveFormsModule,
  ],

  declarations: [
    CnstAutoComboboxComponent,
    CnstButtonSectionComponent,
    CnstSignaturePadComponent,

    CnstMiddleClickDirective,
  ],
  exports: [
    CnstAutoComboboxComponent,
    CnstButtonSectionComponent,
    CnstSignaturePadComponent,

    CnstMiddleClickDirective,
  ],
})
export class CnstModule { }

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges
       } from '@angular/core';

import { User } from '@config/models';
import { sidenav, topnav } from '@config/navigation';

import { AuthService } from '@saikin/auth/auth.service';
import { NavigationItem } from './navigation.model';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnChanges
{
  @Input() user: User;
  @Input() type: string = 'side';

  @Input() withLogout: boolean = false;
  @Output() logout: EventEmitter<void> = new EventEmitter<void>();

  public navigationItems: Array<NavigationItem> = [];

  constructor(private authService: AuthService)
  {
    this.navigationItems = this.getItems();
  }

  public ngOnChanges(changes: SimpleChanges): void
  {
    this.navigationItems = this.getItems();
  }

  private getItems(): Array<NavigationItem>
  {
    const user = this.authService.currentUser;

    let navItems: Array<NavigationItem> = [];

    switch (this.type) {
      case 'side':
        navItems = sidenav;
        break;
      case 'top':
        navItems = topnav;
        break;
      default:
        throw Error(this.type + ': no such navigation found');
    }

    const navFilter = (nav) => nav
      .filter(ni => !ni.permissions ||
              ni.permissions
                .map(p => this.authService.canDo(p)).indexOf(true) > -1)
      .filter(ni => !ni.users || ni.users.indexOf(user.role) > -1);

    if (user) {
     return navFilter(navItems)
      .map((ni) => {
        if (ni.children) {
          ni.children = navFilter(ni.children);
        }
        return ni;
      });
    }

    return [];
  }
}

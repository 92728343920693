import { HashLocationStrategy, LocationStrategy, registerLocaleData
       } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { MatModule } from '@saikin/mat.module';
import { PipesModule } from '@saikin/pipes/pipes.module';

import { AppComponent } from './app.component';
import { AppRouting } from './app.routing';

// Navigation
import { NavigationComponent } from '@saikin/navigation/navigation.component';

// Authentification
import { AuthHttpInterceptor } from '@saikin/auth/auth.interceptor';
import { AuthModule } from '@saikin/auth/auth.module';
import { LoginComponent } from './login/login.component';

// Modules
import { HomeComponent } from './home/home.component';
import { BillingModule } from './billing/billing.module';
import { CouponModule } from './coupon/coupon.module';
import { EngravingModule } from './engraving/engraving.module';
import { ProductModule } from './product/product.module';
import { ImageService } from 'app/image.service';

// Date, Time, Local
import { LOCALE_ID } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CNST_DATE_FORMATS, CnstDateAdapter
       } from '@saikin/cnst/date-adapter/date-adapter';

registerLocaleData(localeDe, 'de');

// Dialogs
import { DeleteDialog } from '@saikin/dialogs/delete-dialog.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HomeComponent,
        NavigationComponent,
        DeleteDialog,
    ],
    imports: [
        BrowserModule,
        MatModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AuthModule,
        AppRouting,
        PipesModule,
        ProductModule,
        EngravingModule,
        BillingModule,
        CouponModule,
    ],
    providers: [
        ImageService,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true
        },
        { provide: LOCALE_ID, useValue: 'de-DE' },
        { provide: DateAdapter, useClass: CnstDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: CNST_DATE_FORMATS },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

import { CnstService } from '@saikin/cnst/cnst.service';

import { Address, Invoice, InvoiceItem } from '@config/models';
import { Injectable } from "@angular/core";

@Injectable()
export class BillingService extends CnstService
{
  public async getAll(): Promise<Array<Invoice>>
  {
    const url = '/invoices';
    const response = await this.http.get(url, this.getHeaders());
    return response.body.map(i => Invoice.fromResponse(i));
  }

  public async getNextInvoiceNumber(): Promise<any>
  {
    const url = '/invoices';
    const response = await this.http.head(url, this.getHeaders());
    return {
      billNumber: response.headers.get('ETag'),
      taxRate: {
        default: Number(response.headers.get('x-tax-default')),
        reduced: Number(response.headers.get('x-tax-reduced')),
      }
    };
  }

  public async getById(invoiceId: number): Promise<Invoice>
  {
    const url = '/invoices/' + invoiceId;
    const response = await this.http.get(url, this.getHeaders());
    return Invoice.fromResponse(response.body);
  }

  public async create(invoice: Invoice): Promise<Invoice>
  {
    const url = '/invoices';
    const payload = invoice.toRequest();
    const response = await this.http.post(url, payload, this.getHeaders());
    invoice = Invoice.fromResponse(response.body);
    return invoice;
  }

  public async update(invoice: Invoice): Promise<Invoice>
  {
    const url = '/invoices/' + invoice.id;
    const payload = invoice.toRequest();
    const headers =  {
      ... this.getHeaders(),
      'If-Match': invoice.etag
    };
    const response = await this.http.patch(url, payload, headers);
    invoice = Invoice.fromResponse(response.body);
    return invoice;
  }

  public async delete(invoice: Invoice): Promise<void>
  {
    const url = '/invoices/' + invoice.id;
    const headers =  {
      ... this.getHeaders(),
      'If-Match': invoice.etag
    };
    await this.http.delete(url, headers);
  }

  public async getAddresses(): Promise<Array<Address>>
  {
    const response = await this.http
      .get('/invoices/addresses', this.getHeaders());
    return response.body.map(a => Address.fromResponse(a));
  }

  public async getPdf(invoiceId: number, original: boolean = true):
    Promise<string>
  {
    const url = '/invoices/' + invoiceId +
                '?issue=' + (original ? 'original' : 'copy');
    const headers = {
      Authorization: 'Bearer ' + this.authService.token,
      Accept: 'application/pdf',
    };

    const response =
      await this.http.get(url, headers, { responseType: 'arraybuffer' });
    return response.body;
  }

  public async sendVoucherEmail(invoice: Invoice): Promise<boolean>
  {
    const url = '/invoices/' + invoice.id + '/coupon_mail';
    const payload = { email: invoice.email };
    const response = await this.http.post(url, payload, this.getHeaders());
    return response.body.result;
  }

  public async getData(itemId: number): Promise<any>
  {
    const url = '/invoice_item/' + itemId;
    const headers = {
      ...this.getHeaders(),
      Accept: 'image/*',
    };
    const response = await this.http.get(url, headers, { responseType: 'arraybuffer' });
    const contentType = response.headers.get("Content-Type").split('/')[1];
    return { content: response.body, type: contentType };
  }
}

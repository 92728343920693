import { MatSnackBar } from '@angular/material/snack-bar';

export const deepCopy = (
  object: any, preventStart = ['$', '_']):
  any => {
  const replacer = (key, value) => {
    if (value === undefined ||
        key && key.length > 0 && preventStart.indexOf(key.charAt(0)) > -1) {
      return;
    }

    return value;
  };

  if (typeof object === 'string' || object instanceof String) {
    return object.slice();
  }

  if (object === undefined) {
    return undefined;
  }

  return (JSON.parse(JSON.stringify(object, replacer)));
};

export const deepCopyWithFunctions = (target: any): any => {
  if (!target) {
    return target;
  }
  if (target instanceof Date) {
    return new Date(target.getTime());
  }

  if (typeof target === 'object') {
    if (typeof (<{ [key: string]: any }> target)[(<any> Symbol).iterator] ===
        'function') {
      const cp = [];
      if ((<any[]> (<any> target)).length > 0) {
        for (const arrayMember of <any[]> (<any> target)) {
          cp.push(deepCopyWithFunctions(arrayMember));
        }
      }
      return cp;
    }
    else {
      const targetKeys = Object.keys(target);
      const cp = <{ [key: string]: any }> {};
      if (targetKeys.length > 0) {
        for (const key of targetKeys) {
          cp[key] =
            deepCopyWithFunctions((<{ [key: string]: any }> target)[key]);
        }
      }
      return cp;
    }
  }

  return target;
};

export const getMimeType = (extension: string): string => {
  switch (extension) {
    case 'csv': return 'text/comma-separated-values';
    case 'xls': return 'application/xls';
    case 'pdf': return 'application/pdf';
    case 'zip': return 'application/zip';
  }
};

export const accessBlobUrl = (
  arrayBuffer: string, name: string, extension = 'pdf'): void =>
{
  const file = new Blob([arrayBuffer], { type: getMimeType(extension) });
  const blobUrl = URL.createObjectURL(file);

  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = name + '.' + extension;

  document.body.appendChild(link);
  link.click();
  link.remove();
};

export class LoadingIndicator
{
  state = false;
  imagePath = '/assets/images/loading.gif';

  public constructor(state = false)
  {
    this.state = state;
  }
}

interface NotificationConfig
{
  indicator?: LoadingIndicator;
  success?: string;
  error?: string|any;

  callback(): Promise<void>;
}

export const getErrorMessage = (error: any, notification: string): string => {
  const errorMessage = error.detail === undefined
    ? (error.statusText ? error.statusText : error)
    : (error.detail === 'model could not be validated correctly'
      ? 'Bitte korrigieren Sie die markierten Felder'
      : error.detail);

  const addendum = error.status === 409
    ? '\nBitte Laden Sie die Seite neu'
    : '';

  return notification + '\n' + errorMessage + addendum;
};

export const executeWithNotification = async (
  snackBar: MatSnackBar, config: NotificationConfig): Promise<boolean> => {
  if (config.indicator !== undefined) {
    config.indicator.state = true;
  }

  let result = false;
  try {
    await config.callback();
    if (config.success) {
      snackBar.open(config.success, 'OK', {
        duration: 3000, panelClass: ['snackbar-success'] });
    }
    result = true;
  }
  catch (error) {
    if (error.status === 500) {
      snackBar.open(
        'Konnte keine Verbindung zu Sage aufbauen', 'OK', {
          duration: 0, panelClass: ['snackbar-error'] });
    }
    if (config.error) {
      const notification = config.error._ ?? config.error;
      const notificationMessage = getErrorMessage(error, notification);
      snackBar.open(
        notificationMessage, 'OK', {
          duration: 0, panelClass: ['snackbar-error'] });
      if (error.fields) {
        for (const _field of error.fields) {
          const name = _field.wrapper ? (_field.wrapper + '_' + _field.property)
            : _field.property;

          let field = document.querySelector('input[name="' + name + '"]');
          if (!field) {
            field =
              document.querySelector('input[ng-reflect-name="' + name + '"]');
          }
          if (field) {
            while (!field.classList.contains('mat-form-field')) {
              field = field.parentElement;
            }
            field.classList.add('mat-form-field-invalid');
          }
        }
      }
    }
  }
  finally {
    if (config.indicator !== undefined) {
      config.indicator.state = false;
    }
  }
  return result;
};

export const sleep = (ms: number): Promise<void> =>
  new Promise(resolve => setTimeout(resolve, ms));

export const convertToNumber = (s: any): number => {
  try {
    s = s.replace(/[^\d,.-]/g, '');
    if (/^-?(?:\d+|\d{1,3}(?:\.\d{3})+)(?:,\d+)?$/.test(s)) {
      s = s.replace(/\./g, '');
      s = s.replace(/,/g, '.');
      return parseFloat(s);
    }
    else {
      s = s.replace(/,/g, ''); // strip out commas
      return parseFloat(s); // convert to number
    }
  }
  catch (e) {
    return Number(s);
  }
};

export const isInRange = (value: string|number,
                          min: string|number, max:string|number): boolean => {
  return convertToNumber(value) >= convertToNumber(min) &&
         convertToNumber(value) <= convertToNumber(max);
};

export const fixDecimalDigits = (value: number, digits = 2): number => {
  const factor = Math.pow(10, digits);
  return Number((Math.round(value * factor) / factor).toFixed(digits));
};


export const dataUriToBlob = (dataURI: string) => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], {type: mimeString});
};

export const applyProperties = (target: any, source: any,
                                properties: Array<string> = []): void => {
  for (const prop of properties) {
    target[prop] = source[prop];
  }
};

export const isDate = (date) => {
  const _date = <any> (new Date(date));
  return (_date !== 'Invalid Date') && !isNaN(_date);
};

export const polling = (callback: Function, interval: number) => {
  const sleep = duration =>
      new Promise(resolve => setTimeout(resolve, duration));
  const poll = (promiseFn, duration) =>
      promiseFn().then(sleep(duration).then(() => poll(promiseFn, duration)));

  poll(() => new Promise(() => callback()), interval);
};

export const numberFormat = (value: number, fraction: number = 2) => {
  return new Intl.NumberFormat('de-de', {
    maximumFractionDigits: fraction
  }).format(value);
};

<ng-container *ngIf="product">
  <h3 class="primary" *ngIf="product.id">{{product.name}} bearbeiten</h3>
  <h3 class="primary" *ngIf="!product.id">Produkt anlegen</h3>

  <section>
    <div class="product-data">
      <div class="text-data">
        <div class="form-group">
          <mat-form-field style="width: 75%;">
            <mat-label>Bezeichnung</mat-label>
            <input matInput placeholder="Bezeichnung"
                   name="product_name"
                   [(ngModel)]="product.name">
          </mat-form-field>
        </div>

        <div class="form-group">
          <mat-form-field>
            <mat-label>Preis</mat-label>
            <input matInput placeholder="Preis"
                   name="product_price"
                   [(ngModel)]="product.price">
            <mat-hint>0 = kein Preis anzeigen</mat-hint>
          </mat-form-field>
        </div>
      </div>
      <div class="image-data">
        <ng-container *ngFor="let image of product.images; let idx = index">
          <a [href]="image.path" target="blank">
            <img [src]="image.path.replace('.jpg', '.th.jpg')" />
          </a>
          <small (click)="deleteImage(idx)">entfernen</small>
        </ng-container>

        <div *ngIf="product.images.length === 0">
          <img *ngIf="!imgUploadInProgress.state"
               src="assets/images/add-image.png"
               (click)="productImage.click()" />
          <mat-progress-spinner *ngIf="imgUploadInProgress.state"
                                mode="indeterminate"
          ></mat-progress-spinner>
          <input type="file" id="productImage" #productImage
                 style="display: none"
                 name="productImage" accept="image/*"
                 (change)="addImage($event.target.files[0])">
        </div>
      </div>
    </div>
    <div class="product-operations">
      <cnst-button-section *ngIf="product"
        [objectId]="product.id" [prefix]="'Produkt'"

        (add)="addProduct();"
        (edit)="updateProduct()"
        (delete)="openDeleteProductDialog()"
        (clear)="remove()"
      ></cnst-button-section>
    </div>
  </section>
</ng-container>


